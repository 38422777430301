import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const PlTechLauncher = () => {
    const {
        // p_code, p_type, g_code, operator, p_client, p_mode, p_game,
        p_username, p_password, p_lang, g_code,
    } = useParams();

    const username = p_username;
    const password = p_password;
    const realMode = 1;
    const language = p_lang; //Please refer to Appendix B
    const gamecode = g_code; //Please refer to google sheet link
    const clientPlatform = "mobile" // web or mobile
    const systemid = "77";
    const casino = "agdragon";

    const login = () => {
        window.iapiLoginAndGetTempToken(username, password, realMode, language, systemid);
    }

    const calloutLogin = (response) => {
        // console.log(response, p_username, p_password, p_lang, g_code);

        if (response.errorCode) {
            if (response.errorText !== undefined) {
                alert("[" + response.errorCode + "] " + response.errorText.replace("<br>", "\r\n"));
            }
            else if (response.playerMessage !== undefined) {
                alert("[" + response.errorCode + "] " + response.playerMessage.replace("<br>", "\r\n"));
            }
            else {
                alert("[" + response.errorCode + "] Login Fail.");
            }
        }
        else {
            window.location.href = `"https://login-ag.nptgp.com/GameLauncher?gameCodeName=" + ${gamecode} + "&username=" + ${username} + "&tempToken=" + ${response?.sessionToken?.sessionToken} + "&casino=" + ${casino} + "&clientPlatform=" + ${clientPlatform} + "&language=" + ${language} + "&playMode=" + ${realMode} + "&deposit=" + "&lobby=" + "&swipeUpOff=true"`;
        }
    };

    window.iapiSetCallout('LoginAndGetTempToken', calloutLogin);

    useEffect(() => {
        if (p_username && p_password && p_lang && g_code) {
            login();
            // navigate("/");
        }
    }, [p_username, p_password, p_lang, g_code]);

    return (
        <div className="d-none">
            <input id="Submit1" type="submit" value="Open Game" onClick={login} />
        </div>
    );
};

export default PlTechLauncher;