import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useColorMode } from "theme-ui";
import { setInnerLoaderFalse, setInnerLoaderTrue } from "../Redux/loaderSlice";

export const useThemeColor = () => {
    const dispatch = useDispatch();
    const [colorMode, setColorMode] = useColorMode();
    const [staticChangableData, setStaticChangableData] = useState(null);

    // Get domain name and set prefixed key
    const domainPrefix = window.location.hostname.replace(/[^a-zA-Z0-9]/g, "-");
    const prefixedThemeKey = `${domainPrefix}-theme-ui-color-mode`;
    const prefixedSystemThemeKey = `${domainPrefix}-system-theme-ui-color-mode`;

    const themeColor = localStorage.getItem(prefixedThemeKey);
    const systemThemeColor = localStorage.getItem(prefixedSystemThemeKey);

    const getData = () => {
        fetch("/changables/data.json", {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Cache-Control": "no-store", // Disable cache
            },
            cache: "no-store", // Prevent browser from caching
        })
            .then((response) => response.json())
            .then((data) => {
                setStaticChangableData(data);
                // Save the initial system theme if not already saved
                if (!systemThemeColor) {
                    localStorage.setItem(prefixedSystemThemeKey, data?.app_theme);
                }
            })
            .catch((error) => console.error("Failed to fetch theme data:", error));
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (staticChangableData?.app_theme) {
            dispatch(setInnerLoaderTrue());

            // If system theme and app_theme differ, trigger a reload
            if (staticChangableData.app_theme !== systemThemeColor) {
                localStorage.setItem(prefixedThemeKey, staticChangableData.app_theme);
                localStorage.setItem(prefixedSystemThemeKey, staticChangableData.app_theme);

                // Trigger a full reload
                // window.location.reload();
            } else if (!themeColor) {
                setColorMode(staticChangableData.app_theme);
                localStorage.setItem(prefixedThemeKey, staticChangableData.app_theme);
            } else {
                setColorMode(themeColor);
            }

            dispatch(setInnerLoaderFalse());
        }
    }, [
        dispatch,
        staticChangableData?.app_theme,
        systemThemeColor,
        themeColor,
        setColorMode,
        prefixedThemeKey,
        prefixedSystemThemeKey,
    ]);

    const changeThemeMode = useCallback(() => {
        dispatch(setInnerLoaderTrue());

        const newColorMode = colorMode === "light" ? "dark" : "light";
        setColorMode(newColorMode);

        // Update localStorage with the new user selection
        localStorage.setItem(prefixedThemeKey, newColorMode);

        dispatch(setInnerLoaderFalse());
    }, [dispatch, colorMode, setColorMode, prefixedThemeKey]);

    return {
        colorMode,
        changeThemeMode,
    };
};